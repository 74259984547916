
import { Vue, Component } from 'vue-property-decorator';
import { ModalBus } from "./modalBus";
import { ModalInput } from "./classes";
import { markRaw } from "vue";

@Component
export default class ModalsContainer extends Vue {
    items: ModalInput[] = [];
    hasMouseDown = false;

    created() {
        ModalBus.registerAddModalCallback((item, props, closeCallback = null) => {
            this.items.push(
                markRaw({
                    modalComponent: item,
                    props: props,
                    closeCallback: closeCallback,
                })
            );
            document.body.classList.add("__modal-open");
        });
        ModalBus.registerCloseLastModal(this.closeLastModal);
        ModalBus.setModalsArray(this.items);
    }

    onDownBg(ev, i) {
        if (ev.target == this.$refs["__modalBg" + i][0]) {
            this.hasMouseDown = true;
        }
    }
    onUpBg(ev, i, blockBgCloseAction) {
        if (blockBgCloseAction) {
            this.hasMouseDown = false;
            return;
        }

        if (this.hasMouseDown) {
            this.hasMouseDown = false;
            if (ev.target == this.$refs["__modalBg" + i][0]) {
                this.closeLastModal();
            }
        }
    }

    closeModal(index) {
        const removed = this.items.splice(index, 1)[0];
        if (removed?.closeCallback) {
            removed.closeCallback();
        }
        if (this.items.length == 0) {
            document.body.classList.remove("__modal-open");
        }
    }
    closeFromBg(ev) {
        this.closeLastModal();
    }
    closeLastModal() {
        const removed = this.items.pop();
        if (removed?.closeCallback) {
            removed.closeCallback();
        }
        if (this.items.length == 0) {
            document.body.classList.remove("__modal-open");
        }
    }
}
