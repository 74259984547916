export function EnumToList(inputEnum: any): { [key: string]: number | string } {
    let ris: any = [];
    for(var prop in inputEnum){
        if (typeof inputEnum[prop] === 'number') {
            var obj = {
                Label: prop.replace(/_/g, ' '),
                Value: inputEnum[prop]
            }
            ris.push(obj);
        }
    }
    return ris;
}

import Vue from 'vue';
import * as OM from '@/Model';
import moment from 'moment';

// let budgetStatuses = EnumToList(OM.BudgetStatus)
// Vue.filter('budgetStatus', function(value: any) {
//     if (value || value == 0) {
//         return budgetStatuses[value];
//     }
// })


Vue.filter('enum', function(value: any, enumType: string) {
    let enumList = EnumToList(OM[enumType]);
    if (value || value == 0) {
        return enumList[value];
    }
})

Vue.filter('date', function(value: any, _format: string, _showTime: boolean) {
    if (value) {
        let format;
        if(!_format){
            format = 'YYYY MMMM DD';
            if(_showTime)
                format += 'HH:mm:ss';
        } else {
            format = _format;
        }
        return moment(value).format(format);
    }
})

Vue.filter('currency', function(value: any, decimals: number) {
    if(!decimals && decimals != 0)
        decimals = 2;
    if (value || value == 0) {
        let val = (value/1).toFixed(decimals).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }
})