import { CommonServices } from '@/services/CommonServices';

class _AuthServices {
    logout() {
        let prom = new Promise<void>((resolve, reject) => {
            CommonServices.destroyToken();
            window.sessionStorage.setItem('email', "");
            window.sessionStorage.setItem('password', "");
            resolve();
        })
        return prom;
    }

    setLoginToken(token: string) {
        return CommonServices._setAuthToken(token);
    }
}

export let AuthServices = new _AuthServices();