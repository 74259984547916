import { ModalInput } from "./classes";
class _ModalBus {
    addModalCb: any;
    closeLastModalCb: any;
    modals: ModalInput[] = [];

    registerAddModalCallback(cb) {
        this.addModalCb = cb;
    }
    addModal(item, props, closeCallback) {
        this.addModalCb(item, props, closeCallback);
    }

    registerCloseLastModal(cb) {
        this.closeLastModal = cb;
    }
    closeLastModal() {
        this.closeLastModalCb();
    }

    setModalsArray(arr: ModalInput[]) {
        this.modals = arr;
    }
    getModals = () => {
        return this.modals;
    };
}

export const ModalBus = new _ModalBus();
