
import router from "@/router";
import { Component, Vue } from "vue-property-decorator";
import { AuthServices } from "@/services/AuthServices";
import store from "@/store";
import * as OM from "@/Model";
import { ToastedServices } from "@/services/ToastedServices";
import { StorageServices } from "@/services/StorageService";
import { CommonServices } from "../services/CommonServices";
import { UserClient } from "@/services/Services";

@Component({
    components: {},
})
export default class Login extends Vue {
    opened: any = null;
    authCode: string = "";
    loginCredentialsModel: OM.LanguageCredential = new OM.LanguageCredential();

    loginWithCredentials() {
        this.loginCredentialsModel.email = this.loginCredentialsModel.email
            .trim()
            .toLowerCase();
        this.loginCredentialsModel.password = this.loginCredentialsModel.password.trim();
        UserClient.login(this.loginCredentialsModel).then(
            (x) => {
                var loggedUser = new OM.LoggedUserVM();
                loggedUser.identifier = x.identifier;
                loggedUser.completeName = x.completeName;
                loggedUser.email = x.email;
                loggedUser.role = x.role;
                AuthServices.setLoginToken(x.token);
                this.setLoginDataAndRedirect(loggedUser);
            },
            (error) => {
                ToastedServices.Error(error);
            }
        );
    }

    setLoginDataAndRedirect(loggedUser) {
        UserClient.getRole().then(
            (x) => {
                loggedUser.role = x;
                StorageServices.setLoggedUser(loggedUser);
                if (loggedUser.role == "admin")
                    router.push("/backoffice/admin/dashboard");
                else if (loggedUser.role == "backendUser")
                    router.push("/backoffice/dashboard");
                else router.push("/user/dashboard");
            },
            (error) => {
                ToastedServices.Error(error);
            }
        );
    }
}
