
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ToastedServices } from '@/services/ToastedServices';
import store from '@/store';

@Component
export default class App extends Vue {

	created() {
        ToastedServices.toastedInstance = this.$toasted;
	}

	get showSpinner(){
        return store.state.showSpinner > 0;
    }
}
