// import Vue from 'vue';
// import * as OM from '@/Model'

export const Locales = {
    english: 'en-US', //eng
    italian: 'it-IT', //ita
    french: 'fr-FR', //fra
    spanish: "es-ES", //esp
    german: "de-DE", //deu
    // czech: "cs-CZ" //csy
    //russian: 'ru-RU'
};

export const LocalesDescription:  { [key: string]: string; } = {};
LocalesDescription[Locales.english] = 'Inglese';
LocalesDescription[Locales.italian] = 'Italiano';
LocalesDescription[Locales.french] = 'Francese';
LocalesDescription[Locales.spanish] = 'Spagnolo';
LocalesDescription[Locales.german] = 'Tedesco';
// LocalesDescription[Locales.czech] = 'Ceco';

export class Role {
    name: string;
    modules: string[];
    constructor(){
        this.name = "";
        this.modules = [];
    }
}

export class Breadcrumb {
    title: string;
    link: string;
    constructor(){
        this.title = "";
        this.link = "";
    }
}
