import Axios, { AxiosInstance, AxiosPromise, AxiosRequestConfig } from 'axios';
import router from '@/router';
import store from '@/store';
import { ToastedServices } from '@/services/ToastedServices';
import { baseUrl, authtoken } from '@/config';

class _CommonServices {
    baseUrl = baseUrl;

    showSpinner(){
        store.state.showSpinner++;
    };
    hideSpinner(){
        store.state.showSpinner--;
    };
    private axios: AxiosInstance;
    defaultGet<T = any>(url: string, params?: any, _showSpinner = true): Promise<T> {
        let req: AxiosRequestConfig = {
            params
        };
        if(_showSpinner)
            this.showSpinner();
        let prom = new Promise<T>((resolve, reject) => {
            this.axios.get<T>(url, req)
            .then((x:any) => {
                this.hideSpinner();
                resolve(x.data);
            }).catch( (err:any) => {
                this.hideSpinner();
                reject(err);
            });
        });
        return prom;
    }
    defaultPost<T = any>(url: string, data?: any, config?: AxiosRequestConfig, _showSpinner = true): Promise<T> {
        if(_showSpinner)
            this.showSpinner()
        let prom = new Promise<T>((resolve, reject) => {
            this.axios.post(url, data, config).then((x:any) => {
                this.hideSpinner();
                resolve(x.data);
            }).catch( (err:any) => {
                console.dir(err);
                this.hideSpinner();
                //ToastedServices.Error("Errore nel salvataggio, compila i dati mancanti");
                reject(err);
            });
        });
        return prom;
    }
    uploadFileToUrl<T = any>(url: string, file: File, params: { [key: string]: any },
        onUploadProgress?: (progress: number) => void, _showSpinner = true): Promise<T> {
        var data = new FormData();
        if (params) {
            for (var key in params) {
                data.append(key, params[key]);
            }
        }
        data.append('file', file);
        var config = {
            onUploadProgress: function (ev: any) {
                if(typeof onUploadProgress == 'function')
                    onUploadProgress((100 * ev.loaded) / ev.total);
            }
        };
        if(_showSpinner)
            this.showSpinner();
        let prom = new Promise<T>((resolve, reject) => {
            return this.axios.post<T>(url, data, config).then(x => {
                this.hideSpinner();
                resolve(x.data);
            })
            .catch( err => {
                this.hideSpinner();
                reject(err);
            });
        });
        return prom;
    }
    uploadFilesToUrl<T = any>(url: string, file: File[], params: { [key: string]: any },
        onUploadProgress?: (progress: number) => void, _showSpinner = true): Promise<T> {
        var data = new FormData();
        if (params) {
            for (var key in params) {
                data.append(key, params[key]);
            }
        }
        for(var i = 0; i < file.length; i++ ){
            data.append('file', file[i]);
            var config = {
                onUploadProgress: function (ev: any) {
                    if(typeof onUploadProgress == 'function')
                        onUploadProgress((100 * ev.loaded) / ev.total);
                }
            };
        }
        if(_showSpinner)
            this.showSpinner();
        let prom = new Promise<T>((resolve, reject) => {
            return this.axios.post<T>(url, data, config).then(x => {
                this.hideSpinner();
                resolve(x.data);
            })
            .catch( err => {
                this.hideSpinner();
                reject(err);
            });
        });
        return prom;
    }
    _setAuthToken(token: string) {
        this.axios.defaults.headers.common['Authorization'] = "Bearer " + token;
        window.sessionStorage.setItem('authtoken', token);
    }
    destroyToken() {
        this.axios.defaults.headers.common['Authorization'] = "";
        window.sessionStorage.removeItem('authtoken');
    }
    getToken() {
        return window.sessionStorage.getItem('authtoken');
    }
    constructor() {
        this.axios = Axios;

        this.axios.interceptors.response.use((response: any) => {
            return response;
        }, (error:any) => {
            //console.log(error);
            if(error.response.state == 400) {
                alert("errore interno");
            }
            if (error.response.status == 401) {
                if(router.currentRoute.path != '/')
                router.push('/?redirect=' + router.currentRoute.fullPath);
            } else {
                if(error.response.data.Level == "warning") {
                    ToastedServices.Warning(error.response.data.Message);
                }
                else if(error.response.data.Level == "validation") {
                    var data = error.response.data.Message;
                    ToastedServices.Validation(data);
                    console.log(data);
                }
                else if(error.response.data.Level == "error") {
                    ToastedServices.Error(error.response.data.Message);
                    console.log(error.response.data.Exception);
                }
                return Promise.reject(error.response.data);
            }
        });
        let token = this.getToken();
        if (token){
            this._setAuthToken(token);
        }
        // let loginResponse = StorageServices.getLoginResponse();
        // if(loginResponse){
        //     store.state.loginResponse = loginResponse;
        // }
    }
}

export let CommonServices = new _CommonServices();