class _ToastedServices {
	
	toastedInstance: any = null;
	
	Success(message: string, options = { duration: 5000 }){
		this.toastedInstance.success(message, options);
	}

	Warning(message: string, options = { duration: 5000 }){
		this.toastedInstance.info(message, options);
	}
	
	Validation(message: string, options = { duration: 10000 }){
		// let ulMessage= "<ul>"
		// message.forEach(x=>{
		// 	ulMessage +="<li>"+x+"</li>"
		// })

		// ulMessage += "</ul>";
		
		this.toastedInstance.info(message, options);
	}

	Error(message: string, options = { duration: 5000 }){
		this.toastedInstance.error(message, options);
	}
	
}

export let ToastedServices = new _ToastedServices();