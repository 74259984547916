import Vue from 'vue'
import { RouteConfig } from 'vue-router';
import VueRouter from 'vue-router';
import Login from './views/Login.vue';

import ComingSoon from './components/ComingSoon.vue';

Vue.use(VueRouter)

let routes: RouteConfig[] = [
	{
		path: '/',
		name: 'Login',
		component: Login
	},
	{
        //Admin views
		path: '/backoffice/admin',
		component: () => import('./views/backoffice/admin/Layout.vue'),
		children: [
			{
				path: 'dashboard',
				name: 'Dashboard Admin',
                component: () => import('./views/backoffice/admin/dashboard/Dashboard.vue'),
			},
			{
				path: 'user-list',
				name: 'UserList Admin',
				component: () => import('./views/backoffice/admin/user/UserList.vue'),
			},
			{
				path: 'customersvehicles',
				name: 'veicoli clienti',
                component: () => import('./views/backoffice/admin/customersvehicles/vehicleList.vue'),
			},
			{
				path: 'customersvehicles/:vehicleIdentifier',
				name: 'veicoli clienti modifica',
                component: () => import('./views/backoffice/admin/customersvehicles/editvehicle.vue'),
			},
			{
				path: 'bookinghistory',
				name: 'storico prenotazioni',
                component: () => import('./views/backoffice/admin/bookinghistory/bookingHistoryList.vue'),
			},
			{
				path: 'bookinghistory/:reservationIdentifier',
				name: 'storico prenotazioni dettaglio',
                component: () => import('./views/backoffice/admin/bookinghistory/editBookingHistory.vue'),
			},
			{
				path: 'marketing',
				name: 'marketing',
				component: () => import('./views/backoffice/admin/marketing/marketing.vue'),
			},
			{
				path: 'marketing/push',
				name: 'push',
				component: () => import('./views/backoffice/admin/marketing/push/push.vue'),
			},
			{
				path: 'customer-list',
				name: 'Customer',
				component: () => import('./views/backoffice/admin/customer/CustomerList.vue'),
			},
			{
				path: 'customer-edit/:customerIdentifier',
				name: 'Customer-edit',
				component: () => import('./views/backoffice/admin/customer/CustomerEdit.vue'),
			},
			{
				path: 'vehicle-list',
				name: 'VehicleList',
                component: () => import('./views/backoffice/admin/vehicle/vehicleLayout.vue'),
                children: [
                    {
						path: 'brands',
						name: 'Brands List',
						component: () => import('./views/backoffice/admin/vehicle/brandList.vue'),
					},{
						path: 'vehicles',
						name: 'Vehicles List',
						component: () => import('./views/backoffice/admin/vehicle/vehicleList.vue'),
					},{
						path: 'apivehicles',
						name: 'API Vehicles List',
						component: () => import('./views/backoffice/admin/vehicle/apiVehicleList.vue'),
					}
                ]
			},{
                path: 'reservations',
                name: 'reservations',
                component: () => import('./views/backoffice/admin/reservation/ReservationList.vue'),
            },{
                path: 'reservations/:reservationIdentifier',
                name: 'reservation edit',
                component: () => import('./views/backoffice/admin/reservation/ReservationEdit.vue'),
            },{
                path: 'area',
                name: 'area list',
                component: () => import('./views/backoffice/admin/area/AreaList.vue'),
            },{
                path: 'locations',
                name: 'locations',
                component: () => import('./views/backoffice/admin/locations/locationList.vue'),
            },{
                path: 'locations/:locationIdentifier',
                name: 'location edit',
                component: () => import('./views/backoffice/admin/locations/locations/LocationEditConfig.vue'),
            },
			{
				path: 'dictionary-list',
				name: 'DictionaryList',
				component: () => import('./views/backoffice/admin/dictionary/DictionaryList.vue'),
			},
			{
				path: 'site-dictionary-list',
				name: 'SiteDictionaryList',
				component: () => import('./views/backoffice/admin/dictionary/SiteDictionaryList.vue'),
			},
			{
                path: 'apilocations/:locationIdentifier',
                name: 'api location edit',
                component: () => import('./views/backoffice/admin/locations/apiLocations/LocationEditConfig.vue'),
            },
			{
				path: 'configurations',
				component: () => import('./views/backoffice/admin/configurations/Configuration.vue'),
				children: [
					{
						path: 'services',
						name: 'Services Configuration',
						component: () => import('./views/backoffice/admin/configurations/service/ServiceConfig.vue'),
					},{
						path: 'accessories',
						name: 'Accessories Configuration',
						component: () => import('./views/backoffice/admin/configurations/accessory/AccessoryConfig.vue'),
					},{
						path: 'baseprices',
						name: 'base prices',
						component: () => import('./views/backoffice/admin/configurations/baseprice/BasePricesConfig.vue'),
                    },{
						path: 'pricetags',
						name: 'tag prices',
						component: () => import('./views/backoffice/admin/configurations/pricetag/PriceTagConfig.vue'),
                    },{
						path: 'pricecondition',
						name: 'condition prices',
						component: () => import('./views/backoffice/admin/configurations/pricecondition/PriceConditionConfig.vue'),
                    },{
						path: 'comfort',
						name: 'comfort',
						component: () => import('./views/backoffice/admin/configurations/comfort/ComfortConfig.vue'),
                    },{
						path: 'useconditions',
						name: 'use conditions',
						component: () => import('./views/backoffice/admin/configurations/useConditions/useConditionConfig.vue'),
                    },{
						path: 'pricerules',
						name: 'rules prices',
						component: () => import('./views/backoffice/admin/configurations/pricerules/PriceRulesConfig.vue'),
                    },{
						path: 'insurance',
						name: 'insurance list',
						component: () => import('./views/backoffice/admin/configurations/insurance/InsuranceConfig.vue'),
                    },{
						path: 'pointofinterest',
						name: 'site poi',
						component: () => import('./views/backoffice/admin/configurations/pointofinterest/PointOfInterestConfig.vue'),
                    }
				]
            },
			{
				path: 'agent-list',
				name: 'AgentList',
                component: () => import('./views/backoffice/admin/agency/AgentList.vue'),
			},
			{
                path: 'agency-list',
                name: 'agencyList',
                component: () => import('./views/backoffice/admin/agency/AgencyList.vue'),
            }
		]
    },
    {//backoffice view
        path: '/backoffice',
		component: () => import('./views/backoffice/backenduser/Layout.vue'),
		children: [
            {
				path: 'dashboard',
				name: 'Dashboard',
                component: () => import('./views/backoffice/backenduser/dashboard/Dashboard.vue'),
			},
			{
				path: 'user-list',
				name: 'UserList',
				component: () => import('./views/backoffice/backenduser/user/UserList.vue'),
			},
			{
				path: 'user-list/:userIdentifier',
				name: 'user edit',
				component: () => import('./views/backoffice/backenduser/user/UserEdit.vue'),
			},
			{
				path: 'reservation-list',
				name: 'ReservationList',
				component: () => import('./views/backoffice/backenduser/reservation/ReservationList.vue'),
			},
			{
				path: 'reservation/:identifierReservation',
				name: 'EditReservation',
				component: () => import('./views/backoffice/backenduser/reservation/ReservationEdit.vue'),
			},
			{
				path: 'reservation/newReservation/:step',
				name: 'NewReservation',
				component: () => import('./views/backoffice/backenduser/reservation/ReservationNew.vue'),
			},
			{
				path: 'reservation/newReservation/:step/summary',
				name: 'NewReservation summary',
				component: () => import('./views/backoffice/backenduser/reservation/components/newReservation/ReservationSummary.vue'),
			},
			{
				path: 'reservation/newReservation/:step/summary/payment',
				name: 'NewReservation summary',
				component: () => import('./views/backoffice/backenduser/reservation/components/newReservation/ReservationPayment.vue'),
			},
			{
				path: 'reservation/newReservation/detail/',
				name: 'ReservationDetail',
				component: () => import('./views/backoffice/backenduser/reservation/ReservationDetail.vue'),
			},
			{
				path: 'approvalvehicle',
				name: 'veicoli da approvare',
                component: () => import('./views/backoffice/backenduser/approvalvehicle/Vehicle.vue'),
			},
			{
				path: 'approvalvehicle/:vehicleIdentifier',
				name: 'edit vehicle',
                component: () => import('./views/backoffice/backenduser/approvalvehicle/VehicleEdit.vue'),
			},
			{
				path: 'availability',
				name: 'Disponibilita',
                component: ()  => import('./views/backoffice/backenduser/availability/vehicleList.vue'),
			},
			{
				path: 'availability/:vehicleIdentifier',
				name: 'Disponibilita del veicolo',
                component: ()  => import('./views/backoffice/backenduser/availability/availabilityList.vue'),
			},
			{
				path: 'balance',
				name: 'Balance',
                component: () => import('./views/backoffice/backenduser/balance/Balance.vue'),
			},
			{
				path: 'balance/details',
				name: 'Balance details',
                component: () => import('./views/backoffice/backenduser/balance/details/Details.vue'),
			},
			{
				path: 'balance/details/user/:userIdentifier',
				name: 'User details',
                component: () => import('./views/backoffice/backenduser/balance/details/userDetail/userDetail.vue'),
			},
			{
				path: 'balance/details/location/:locationIdentifier',
				name: 'Location details',
                component: () => import('./views/backoffice/backenduser/balance/details/locationDetail/locationDetail.vue'),
			},
			{
				path: 'balance/users',
				name: 'Balance users',
                component: () => import('./views/backoffice/backenduser/balance/users/UserList.vue'),
			},
			{
				path: 'balance/locations',
				name: 'Balance locations',
                component: () => import('./views/backoffice/backenduser/balance/locations/LocationsList.vue'),
			},
        ]
    }
]

const Router = new VueRouter({
    routes
})

export default Router;