import * as OM from '@/Model';
import { Locales } from '@/viewModel';

class _StorageServices  {

    private readFromSessionStorage<T>(lsName: string): T | null {
        let data = window.sessionStorage.getItem(lsName);
        if(data){
            return JSON.parse(data);
        } else {
            return null;
        }
    }

    getLoggedUser() {
        return this.readFromSessionStorage<OM.LoggedUserVM>('loggedUser');
    }
    setLoggedUser(loggedUser: OM.LoggedUserVM) {
        if(!loggedUser.preferredCulture)
            loggedUser.preferredCulture = Locales.italian;
        window.sessionStorage.setItem('loggedUser', JSON.stringify(loggedUser));
    }

    SetLanguage(language: string) {
        window.sessionStorage.setItem('locale', language);
    }

    GetLanguage() {
        let data = window.sessionStorage.getItem('locale');
        if(!data){
            return '';
        } else {
            return data;
        }
    }

    getReservation() {
        return this.readFromSessionStorage<OM.ReservationVm>('reservation');
    }
    setReservation(reservation: OM.ReservationVm) {
        window.sessionStorage.setItem('reservation', JSON.stringify(reservation));
    }

}

export let StorageServices = new _StorageServices();