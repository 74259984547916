import Vue from 'vue'
import Vuex from 'vuex'
import { LoggedUserVM } from '@/Model'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    showSpinner: 0,
    selectedLanguage: "",
    loggedUser: new LoggedUserVM(),
    consts: {
        localizedValues: [],
    }
  },
  mutations: {

  },
  actions: {

  }
})
