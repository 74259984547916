import { PluginObject } from "vue";
import ModalsContainer from './modalsContainer.vue';
import { ModalBus } from './modalBus';

var ModalPlugin: PluginObject<{}> = {
    install(Vue, options = {}) {
        if (this.installed) {
            return
        }

        Vue.component('ModalsContainer', ModalsContainer);
        this.installed = true

        Vue.prototype.$modalPlugin = {
            modals: ModalBus.getModals,
            show(modal, props, closeCallback = null) {
                ModalBus.addModal(modal, props, closeCallback);
            },
            closeAll() {
                let modals = ModalBus.getModals();
                let howManyTimes = modals.length;
                for (let i = 0; i < howManyTimes; i++) {
                    ModalBus.closeLastModal();
                }
            },
            closeLast() {
                ModalBus.closeLastModal();
            },
        }
    }
}

export default ModalPlugin;