import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'bootstrap-vue/dist/bootstrap-vue.min.css'
import 'vue-select/dist/vue-select.css';
import '@/css/style.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'chart.js';

import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
//import Tooltip from './components/tooltip.vue';
import '@/filters';
import { TableComponent, TableColumn } from 'vue-table-component';
import vSelect from 'vue-select';

//Bootstap-vue
import { VBTooltip } from 'bootstrap-vue';
Vue.directive("b-tooltip", VBTooltip);
//import { VBTooltipPlugin } from 'bootstrap-vue'
//Vue.use(VBTooltipPlugin)

import 'lightpick/css/lightpick.css';
import 'lightpick/lightpick.js';

import '@/directives';

Vue.use(require('vue-moment'));

import ModalPlugin from '@/plugin/modal'
Vue.use(ModalPlugin)

Vue.component('table-component', TableComponent);
Vue.component('table-column', TableColumn);
//Vue.component('tooltip', Tooltip);
Vue.component('v-select', vSelect)

import Toasted from 'vue-toasted';
Vue.use(Toasted)

Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')